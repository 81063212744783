export default function ({ $axios, error: nuxtError, app }) {
  let requestUrl = '';
  let isSafari;

  $axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      /*
       * Server side check to see if response of an endpoint is 401 unauthorized
       * In this case we do a logout by resetting all tokens and show a notification about it
       */

      const is401 = error.response.status === 401;
      const hasToken = app.$auth.$storage._state['_token.local'];
      const isRefreshEndpoint = error.response.config.url.includes('refresh');
      const isClient = process.client;

      if (is401 && hasToken && !isRefreshEndpoint) {
        try {
          await app.$auth.refreshTokens();

          return $axios.request(error.config);
        } catch (e) {
          app.$auth.strategy.token.reset();
          app.$notification({ message: 'Je bent uitgelogd', type: 'success' });

          if (process.client) {
            window.location.reload();
          }
        }

        return Promise.resolve();
      } if (is401 && hasToken && isRefreshEndpoint && !isClient) {
        app.$auth.strategy.token.reset();
        app.$notification({ message: 'Je bent uitgelogd', type: 'success' });

        return Promise.resolve();
      }

      return Promise.reject(error);
    },
  );

  $axios.onRequest((config) => {
    requestUrl = config.url;
  });
  $axios.onError((error) => {
    const response = (error.response || { config: {} });
    const url = response.config.url || '';
    const isPageError = url.includes('pages') || url.includes('pages/personal');

    if (typeof navigator !== 'undefined') {
      isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1
        && navigator.userAgent
        && navigator.userAgent.indexOf('CriOS') == -1
        && navigator.userAgent.indexOf('FxiOS') == -1;
    }

    /*
     * Client side redirect responses in Safari don't clone the headers.
     * This results in a network and cors error
     * This code reloads the page in this case, because on server-side this does work.
     */
    if (requestUrl.includes('pages/personal') && process.client && !error.response && isSafari) {
      window.location.reload();
    }

    if (response.status === 404 && isPageError) {
      nuxtError({
        statusCode: 404,
        title: 'Pagina niet gevonden',
        message: 'De pagina die je zocht kon niet gevonden worden',
      });
    } else if (response.status === 500) {
      console.log(response);
      nuxtError({
        statusCode: response.status,
        title: 'Er ging iets mis',
        message: 'De pagina kon niet worden geladen door een fout',
      });
    }

    Promise.reject(error);
  });
}
