export const enhancedEcommerceClear = {
  event: null,
  ecommerce: null,
  eventCategory: null,
  eventAction: null,
  orderTime: null,
  orderValue: null,
  eventValue: null,
  routeName: null,
  pageType: null,
  pageUrl: null,
  pageTitle: null,
};

export const enhancedEcommerce = {
  methods: {
    gtmPush(data) {
      this.$gtm.push(enhancedEcommerceClear);
      this.$gtm.push(data);
    },
    gtmPushEcommerce(data, allowNonLogged = false, checkIfUserIsFresh = false) {
      if (allowNonLogged || this.$auth.loggedIn) {
        if (checkIfUserIsFresh) {
          if (this.$auth.loggedIn && !this.$auth.user.fresh) return;
        }

        this.gtmPush({ ecommerce: null });
        this.gtmPush(data);
      }
    },
  },
};

export default enhancedEcommerce;
