<template lang="pug">
.default-layout
  notification(
    :active="notification.active"
    :message="notification.message"
    :timeout="notification.timeout"
    :type="notification.type"
  )
  navbar
  nuxt.page
  main-footer(
    :lists="footerLists"
    :logos="footerLogos"
    :socials="footerSocials"
  )
  favorite-dropdown(v-if="$auth.user")
  cart-popup(v-model="cartPopup")
  category-popup(v-model="categoryPopup")
  has-update(v-if="hasUpdate")
  general-popup(
    v-model="flashMessageOpen"
    :title="flashMessage.title"
    :message="flashMessage.message"
    :showOnce="flashMessage.showOnce"
    :popupName="flashMessage.popupName"
    :primaryButton="flashMessage.primaryButton"
    :secondaryButton="flashMessage.secondaryButton"
  )
  registration-popup(
    v-model="registrationPopup"
  )
  ios-pwa-install
  script(
    v-if="isHofwebStaging"
    type="text/javascript"
    src="https://indexonline.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-ia3qmx/b/9/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=nl-NL&collectorId=0c75b08c"
  )
</template>

<script>
import { mapGetters } from 'vuex';
import { intercomMixin } from '@/mixins/intercomMixin';
import { authFetchMixin } from '@/mixins/authFetchMixin';

export default {
  components: {
    navbar: () => import('@/components/navbar/navbar'),
    notification: () => import('@/components/notification'),
    'has-update': () => import('@/components/hasUpdate'),
    'main-footer': () => import('@/components/mainFooter'),
    'favorite-dropdown': () => import('@/components/favorites/favoriteDropdown'),
    'cart-popup': () => import('@/components/cart/cartPopup'),
    'category-popup': () => import('@/components/category/categoryPopup'),
    'general-popup': () => import('@/components/generalPopup'),
    'registration-popup': () => import('@/components/registrationPopup'),
    'ios-pwa-install': () => import('@/components/iosPwaInstall'),
  },
  mixins: [intercomMixin, authFetchMixin],
  data() {
    return {
      isHofwebStaging: process.env.domainName === 'www-acc.hofweb.nl',
      regpop: true,
      menuItems: [
        { name: 'Producten', path: '/producten', categories: true },
        { name: 'Recepten', path: '/recepten', icon: 'fa-utensils-alt' },
        { name: 'Boeren', path: '/boeren', icon: 'fa-tractor' },
        { name: 'Verhalen', path: '/verhalen', icon: 'fa-radio' },
        { name: 'Over ons', path: '/dit-zijn-wij', icon: 'fa-farm' },
      ],
      footerLists: [
        {
          title: 'Bestellen & Bezorgen',
          collapsed: true,
          items: [
            { title: 'Bestel verser en makkelijker', link: '/bestellen-bezorgen/bestellen' },
            { title: '2 x per week in jouw regio', link: '/bestellen-bezorgen/bezorgregio-hofweb' },
            { title: 'Gemakkelijk thuisbezorgd', link: '/bestellen-bezorgen/makkelijk-thuis-bezorgd' },
            { title: 'Flexibel afhalen', link: '/bestellen-bezorgen/afhaalpunten' },
            { title: 'Bezorgkosten & Minimum orderbedrag', link: '/bestellen-bezorgen/bezorgkosten' },
            { title: 'Niet tevreden? Geld terug!', link: '/bestellen-bezorgen/niet-tevreden-geld-terug' },
          ],
        },
        {
          title: 'Contact & Service',
          collapsed: true,
          items: [
            { title: 'Contact met Hofweb', link: '/contact-service/contact' },
            { title: 'Veelgestelde vragen', link: '/contact-service/veelgestelde-vragen' },
            { title: 'Alle administratieve stappen op een rij', link: '/contact-service/administratieve-stappen-op-een-rij' },
            { title: 'Minder verpakking & Statiegeld', link: '/contact-service/statiegeld' },
            { title: 'Bezorging rondom feestdagen', link: '/contact-service/bestellen-bezorgen-rondom-feestdagen' },
            { title: 'Werken bij Hofweb', link: '/werken-bij-hofweb' },
          ],
        },
      ],
      footerSocials: [
        { icon: 'fa-instagram', link: 'https://instagram.com/hofweb' },
        { icon: 'fa-facebook-f', link: 'https://nl-nl.facebook.com/hofweb/' },
      ],
      hasUpdate: false,
      flashMessageOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      footerLogos: 'shared/footerLogos',
      notification: 'shared/notification',
      intercomUserHash: 'intercomUserHash',
    }),
    cartPopup: {
      get() {
        return this.$store.getters['shared/cartPopup'].open;
      },
      set(value) {
        this.$store.dispatch('shared/toggleCartPopup', value);
      },
    },
    categoryPopup: {
      get() {
        return this.$store.getters['shared/categoryPopup'];
      },
      set(value) {
        this.$store.dispatch('shared/toggleCategoryPopup', value);
      },
    },
    registrationPopup: {
      get() {
        return this.$store.getters['shared/registrationPopup'];
      },
      set(value) {
        this.$store.dispatch('shared/toggleRegistrationPopup', value);
      },
    },
    flashMessage() {
      if (this.$auth.user?.flashMessages && this.$auth.user?.flashMessages.length) {
        const flashMessage = this.$auth.user?.flashMessages.find((message) => message.type === 'pickzone-info');

        if (flashMessage && !localStorage.pickzoneInfo) {
          this.flashMessageOpen = true;

          return {
            ...flashMessage,
            title: 'Extra aandacht voor diepvriesproduct!',
            showOnce: true,
            popupName: 'pickzoneInfo',
            primaryButton: {
              class: 'primary',
              text: 'Begrepen en doorgaan',
            },
            secondaryButton: null,
          };
        }
      }
      return {};
    },
  },
  watch: {
    '$auth.user.id': {
      async handler() {
        /* Delete the precached page on login or logout and unregister service worker.
         * This is to prevent from the precached homepage to be used when logged in,
         * which is taking the authentication visibility with it (looks like you're not logged in).
         */
        if (process.client && typeof caches !== 'undefined') {
          const cacheKeys = await caches.keys();
          const precache = cacheKeys.find((cacheKey) => cacheKey.includes('precache'));
          const [serviceWorker] = await navigator.serviceWorker.getRegistrations();

          // Delete only the precache
          caches.delete(precache);
          // A new service working will be registered automatically
          // serviceWorker.unregister();
        }
      },
    },
  },
  async mounted() {
    this.bootIntercom();

    this.authFetch();

    const workbox = await window.$workbox;
    if (workbox) {
      workbox.addEventListener('controlling', (event) => {
        if (event.isUpdate) {
          this.hasUpdate = true;
        }
      });
    }
  },
};
</script>

<style lang="scss">
@import '~/assets/css/main.scss';

.page {
  min-height: 100vh;
  padding: 130px 0 60px 0;

  @media screen and (max-width: $breakpoint-md) {
    padding: 60px 0 60px 0;
  }
}
</style>
