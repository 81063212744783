import Vue from 'vue';
import algoliaSearch from 'algoliasearch';

const algoliaClient = algoliaSearch(process.env.algoliaId, process.env.algoliaKey, {
  headers: {
    origin: process.env.domainName ? `https://${process.env.domainName}` : 'http://localhost.index.nl:8080',
  },
});

const productIndex = algoliaClient.initIndex(process.env.algoliaIndexProduct);
const productSortedIndex = algoliaClient.initIndex(process.env.algoliaIndexSortedProduct);
const recipesIndex = algoliaClient.initIndex(process.env.algoliaIndexRecipes);
const suppliersIndex = algoliaClient.initIndex(process.env.algoliaIndexSuppliers);
const farmersIndex = algoliaClient.initIndex(process.env.algoliaIndexFarmers);
const storiesIndex = algoliaClient.initIndex(process.env.algoliaIndexStories);
const mixMatchesIndex = algoliaClient.initIndex(process.env.algoliaIndexMixMatches);

export const state = () => ({
  productsData: {},
  recipesData: {},
  sortedProductsData: [],
  sortedProductsFacets: [],
  sortedProductsMeta: {},
  paginationPages: 1,
  suppliers: [],
  supplierStats: {},
  farmers: [],
  farmerStats: {},
  stories: [],
  storiesStats: {},
  mixMatches: [],
  mixMatch: [],
  mixMatchesStats: {},
  productOffers: [],
  productOffersStats: {},
});

export const getters = {
  productsHits: (state) => state.productsData.hits || [],
  productsStats: (state) => state.productsData,
  recipesHits: (state) => state.recipesData.hits || [],
  recipesStats: (state) => state.recipesData,
  sortedProductsHits: (state) => state.sortedProductsData,
  sortedProductsTotalHits: (state) => state.sortedProductsDataTotalHits,
  sortedProductsFacets: (state) => state.sortedProductsFacets,
  sortedProductsMeta: (state) => state.sortedProductsMeta,
  paginationPages: (state) => state.paginationPages,
  suppliers: (state) => state.suppliers,
  supplierStats: (state) => state.supplierStats,
  farmers: (state) => state.farmers,
  farmerStats: (state) => state.farmerStats,
  stories: (state) => state.stories,
  storiesStats: (state) => state.storiesStats,
  mixMatches: (state) => state.mixMatches,
  mixMatch: (state) => state.mixMatch,
  mixMatchesStats: (state) => state.mixMatchesStats,
  productOffers: (state) => state.productOffers,
  productOffersStats: (state) => state.productOffersStats,
};

export const mutations = {
  setProductsData(state, payload) {
    state.productsData = payload;
  },
  setRecipesData(state, payload) {
    state.recipesData = payload;
  },
  addRecipesHits(state, payload) {
    state.recipesData.hits.push(...payload);
  },
  setSortedProductsData(state, payload) {
    state.sortedProductsData = payload;
  },
  setSortedProductsDataTotalHits(state, payload) {
    state.sortedProductsDataTotalHits = payload;
  },
  addSortedProductsData(state, payload) {
    state.sortedProductsData.push(...payload);
  },
  setSortedProductsFacets(state, payload) {
    state.sortedProductsFacets = payload;
  },
  setSortedProductsMeta(state, payload) {
    state.sortedProductsMeta = payload;
  },
  setPaginationPages(state, payload) {
    state.paginationPages = payload;
  },
  setFacetCollapse(state, payload) {
    Vue.set(state.sortedProductsFacets[payload.index], 'collapsed', !payload.collapsed);
  },
  setFacetExtend(state, payload) {
    Vue.set(state.sortedProductsFacets[payload.index], 'extended', !payload.extended);
  },
  setSuppliers(state, payload) {
    state.suppliers = payload;
  },
  addSuppliers(state, payload) {
    state.suppliers.push(...payload);
  },
  setSupplierStats(state, payload) {
    state.supplierStats = payload;
  },
  setFarmers(state, payload) {
    state.farmers = payload;
  },
  addFarmers(state, payload) {
    state.farmers.push(...payload);
  },
  setFarmerStats(state, payload) {
    state.farmerStats = payload;
  },
  setStories(state, payload) {
    state.stories = payload;
  },
  addStories(state, payload) {
    state.stories.push(...payload);
  },
  setStoriesStats(state, payload) {
    state.storiesStats = payload;
  },
  setMixMatches(state, payload) {
    state.mixMatches = payload;
  },
  setMixMatchesStats(state, payload) {
    state.mixMatchesStats = payload;
  },
  setMixMatch(state, payload) {
    state.mixMatch = payload;
  },
  setProductOffers(state, payload) {
    state.productOffers = payload;
  },
  setProductOffersStats(state, payload) {
    state.productOffersStats = payload;
  },
};

export const actions = {
  async getCategoryIndexSortedProduct({ commit, state }, {
    category = null, page = 0, facetFilters = [], searchValue = '', dynamicIndex = '', resetFacets = false, hitsPerPage = 20, tagFilters = [],
  }) {
    const dynamicAlgoliaIndex = algoliaClient.initIndex(dynamicIndex);
    const algoliaIndexToUse = dynamicIndex ? dynamicAlgoliaIndex : productSortedIndex;
    const categoryQueryPart = category ? `shop_categories:${category} AND` : '';
    const data = await algoliaIndexToUse.search('', {
      query: searchValue,
      hitsPerPage,
      page,
      maxValuesPerFacet: 100,
      filters: `${categoryQueryPart} NOT status:"Unavailable" AND wholesale:false`,
      facetFilters,
      facets: [
        'origin_type',
        'groups',
        'supplier_brand',
      ],
      tagFilters,
    });

    commit('setSortedProductsMeta', data);

    if (page === 0) {
      commit('setSortedProductsData', data.hits);
    } else {
      commit('addSortedProductsData', data.hits);
    }

    commit('setPaginationPages', data.nbPages);
    commit('setSortedProductsDataTotalHits', data.nbHits);

    let facets;

    if (!state.sortedProductsFacets.length || resetFacets) {
      facets = Object.entries(data.facets)
        .map(([groupName, values]) => ({
          name: groupName,
          collapsed: false,
          extended: false,
          items: Object.entries(values).map(([name, amount]) => ({
            name,
            amount,
            checked: String(facetFilters).includes(`${groupName}:${name}`),
          })),
        }))
        .sort((filter) => (filter.name === 'origin_type' ? -1 : 1))
        .map((filter) => ({
          ...filter,
          collapsed: filter.name !== 'origin_type',
        }));
    } else {
      facets = state.sortedProductsFacets.map((facet) => ({
        ...facet,
        items: facet.items.map((item) => ({
          ...item,
          checked: String(facetFilters).includes(`${facet.name}:${item.name}`),
        })),
      }));
    }
    commit('setSortedProductsFacets', facets);
  },
  async searchProduct({ commit }, {
    hitsPerPage = 20, searchValue = '', filters = '', facetFilters = [], saveToStore = true,
  }) {
    const data = await productIndex.search('', {
      query: searchValue,
      hitsPerPage,
      page: 0,
      maxValuesPerFacet: 100,
      filters: `NOT custom_ranking:"-9999" AND NOT status:"Unavailable" AND wholesale:false ${filters}`,
      facetFilters,
      facets: [],
      tagFilters: [],
    });

    if (saveToStore) {
      commit('setProductsData', data);
    }

    return data;
  },
  async searchSortedProduct(context, payload) {
    const data = await productSortedIndex.search(payload);
  },
  async searchRecipe({ commit }, {
    hitsPerPage = 20, page = 0, searchValue = '', filters = [], facets = [],
  }) {
    const data = await recipesIndex.search('', {
      query: searchValue,
      hitsPerPage,
      page,
      facets,
      facetFilters: filters,
    });
    commit('setPaginationPages', data.nbPages);

    if (page === 0) {
      commit('setRecipesData', data);
    } else {
      commit('addRecipesHits', data.hits);
    }
  },
  updateFacetCollapse({ commit }, payload) {
    commit('setFacetCollapse', payload);
  },
  updateFacetExtend({ commit }, payload) {
    commit('setFacetExtend', payload);
  },
  async getSuppliers({ commit }, payload) {
    const data = await suppliersIndex.search('', payload);

    commit(payload.page === 0 ? 'setSuppliers' : 'addSuppliers', data.hits);
    commit('setSupplierStats', data);
    commit('setPaginationPages', data.nbPages);
  },
  async getFarmers({ commit }, payload) {
    const data = await farmersIndex.search('', payload);

    commit(payload.page === 0 ? 'setFarmers' : 'addFarmers', data.hits);
    commit('setFarmerStats', data);
    commit('setPaginationPages', data.nbPages);
  },
  async getStories({ commit }, payload) {
    const data = await storiesIndex.search('', payload);

    commit(payload.page === 0 ? 'setStories' : 'addStories', data.hits);
    commit('setStoriesStats', data);
    commit('setPaginationPages', data.nbPages);
  },
  async getMixMatches({ commit }, {
    hitsPerPage = 200, page = 0, query = '', filters = '', facets = [], facetFilters = [], tags = [],
  }) {
    const data = await mixMatchesIndex.search('', {
      hitsPerPage,
      page,
      query,
      filters,
      facets,
      facetFilters,
      tags,
    });
    commit('setMixMatches', data.hits);
    commit('setMixMatchesStats', data);
    commit('setPaginationPages', data.nbPages);
  },
  async getMixMatch({ commit }, query) {
    const data = await mixMatchesIndex.search(`"${query}"`, {
      hitsPerPage: 1,
    });
    const [mixMatch] = data.hits || [];

    commit('setMixMatch', mixMatch || {});
  },
  async getProductOffers({ commit }, {
    page = 0, query = '', hitsPerPage = 20, tagFilters = [],
  }) {
    const data = await productSortedIndex.search('', {
      query,
      hitsPerPage,
      page,
      filters: `NOT status:"Unavailable" AND wholesale:false`,
      tagFilters: ['active_discount', ...tagFilters],
    });

    commit('setProductOffers', data.hits);
    commit('setProductOffersStats', data);
    commit('setPaginationPages', data.nbPages);
  },
};
