<template lang="pug">
.maintenance-layout
  nuxt
</template>

<script>
import { intercomMixin } from '@/mixins/intercomMixin';

export default {
  components: {},
  mixins: [intercomMixin],
  data() {
    return {};
  },
};
</script>

<style lang="scss">
@import '~/assets/css/main.scss';

</style>
