import Vue from 'vue';
import { enhancedEcommerceClear } from '~/mixins/enhancedEcommerce';

export const state = () => ({
  page: {},
  recipes: [],
  recipesMeta: [],
  recipesTags: {},
  recipe: {},
  farmers: [],
  mainNavigation: [],
  fullAddress: {},
  addresses: [],
  address: {},
  cart: {},
  orders: {},
  order: {},
  upcomingDeliveries: [],
  pickupPoints: [],
  deliveryData: {},
  recurringProducts: [],
  productLists: [],
  newsItems: [],
  productList: {},
  favoritedRecipes: {},
  validationErrors: {},
  responseMessage: '',
  crates: [],
  holidays: [],
  verifiedPaymentInfo: {},
  packageSlip: {},
  supplierProducts: [],
});

export const getters = {
  page: (state) => state.page,
  recipes: (state) => state.recipes,
  recipesMeta: (state) => state.recipesMeta,
  recipesTags: (state) => state.recipesTags,
  recipe: (state) => state.recipe,
  farmers: (state) => state.farmers,
  mainNavigation: (state) => state.mainNavigation,
  mainNavigationGlobal: (state) => state.mainNavigation.filter((item) => item.show_in_main_nav),
  fullAddress: (state) => state.fullAddress,
  address: (state) => state.address,
  addresses: (state) => state.addresses,
  orders: (state) => state.orders.data,
  ordersMeta: (state) => state.orders.meta,
  order: (state) => state.order,
  deliveryData: (state) => state.deliveryData,
  recurringProducts: (state) => state.recurringProducts,
  productLists: (state) => state.productLists,
  productList: (state) => state.productList,
  newsItems: (state) => state.newsItems,
  upcomingDeliveries: (state) => state.upcomingDeliveries,
  pickupPoints: (state) => state.pickupPoints,
  cart: (state) => state.cart,
  favoritedRecipesData: (state) => state.favoritedRecipes.data,
  favoritedRecipesMeta: (state) => state.favoritedRecipes.meta,
  validationErrors: (state) => state.validationErrors,
  responseMessage: (state) => state.responseMessage,
  cratesData: (state) => state.crates.data,
  holidays: (state) => state.holidays,
  verifiedPaymentInfo: (state) => state.verifiedPaymentInfo,
  packageSlip: (state) => state.packageSlip,
  supplierProducts: (state) => state.supplierProducts,

};

export const mutations = {
  setPage(state, payload) {
    state.page = payload;
  },
  setRecipes(state, payload) {
    state.recipes = payload;
  },
  setRecipesMeta(state, payload) {
    state.recipesMeta = payload;
  },
  setRecipesTags(state, payload) {
    state.recipesTags = payload;
  },
  setRecipe(state, payload) {
    state.recipe = payload;
  },
  setFarmers(state, payload) {
    state.farmers = payload;
  },
  setMainNavigation(state, payload) {
    state.mainNavigation = payload;
  },
  setDeliveryData(state, payload) {
    state.deliveryData = payload;
  },
  setUpcomingDeliveries(state, payload) {
    state.upcomingDeliveries = payload;
  },
  setFullAddress(state, payload) {
    state.fullAddress = payload;
  },
  setAddresses(state, payload) {
    state.addresses = payload;
  },
  setAddress(state, payload) {
    state.address = payload;
  },
  setCustomer(state, payload) {
    state.customer = payload;
  },
  setOrders(state, payload) {
    state.orders = payload;
  },
  setOrder(state, payload) {
    state.order = payload;
  },
  setPickupPoints(state, payload) {
    state.pickupPoints = payload;
  },
  setProductLists(state, payload) {
    state.productLists = payload;
  },
  setProductList(state, payload) {
    state.productList = payload;
  },
  addProductList(state, payload) {
    state.productLists.push({
      ...payload,
      items: { data: [] },
    });
  },
  addProductListItem(state, payload) {
    const listIndex = state.productLists.findIndex((list) => list.id === payload.product_list_id);

    state.productLists[listIndex].items.data.push(payload);
  },
  deleteProductlistItem(state, { listId, productId }) {
    const listIndex = state.productLists.findIndex((list) => list.id === listId);
    state.productLists[listIndex].items.data = state.productLists[listIndex].items.data.filter((item) => item.product_id !== productId);
  },
  setRecurringProducts(state, payload) {
    state.recurringProducts = payload;
  },
  setNewsItems(state, payload) {
    state.newsItems = payload;
  },
  setFavoritedRecipes(state, payload) {
    state.favoritedRecipes = payload;
  },
  setValidationErrors(state, payload) {
    state.validationErrors = payload;
  },
  unsetValidationError(state, { key }) {
    Vue.delete(state.validationErrors, key);
  },
  setResponseMessage(state, payload) {
    state.responseMessage = payload;
  },
  setCrates(state, payload) {
    state.crates = payload;
  },
  setHolidays(state, payload) {
    state.holidays = payload;
  },
  setVerifiedPaymentInfo(state, payload) {
    state.verifiedPaymentInfo = payload;
  },
  setPackageSlip(state, payload) {
    state.packageSlip = payload;
  },
  setSupplierProducts(state, payload) {
    state.supplierProducts = payload;
  },
};

export const actions = {
  async getPage({ commit }, { path, include = '', actualPath }) {
    const parsedPath = path
      .replace(/^\/|\/$/g, '') // Remove trailing slashes
      .replace(/\//g, '*'); // Replace all left / with *
    const params = include ? { params: { include: `${include},category.header_page` } } : {};
    const usePersonal = this.$auth.loggedIn ? 'personal/' : '';

    try {
      const { data } = (await this.$axios.$get(`/customer-api/v2/pages/${usePersonal}${parsedPath}`, { ...params }));

      if (data) {
        this.$gtm.push(enhancedEcommerceClear);
        this.$gtm.push({
          event: 'nuxtRoute',
          pageTitle: data.meta_title,
          pageType: 'PageView',
          pageUrl: actualPath || path,
        });

        commit('setPage', data);
      }
    } catch (e) {
      commit('setPage', {});
    }
  },
  async getRecipes({ commit }, { page = 1, tags = [] }) {
    const { data, meta } = await this.$axios.$get('/customer-api/v2/recipes', {
      params: {
        page,
        tags: tags.join(''),
      },
    });

    commit('setRecipes', data);
    commit('setRecipesMeta', meta);
  },
  async getRecipesTags({ commit }) {
    const data = await this.$axios.$get('/customer-api/v2/recipes/tags');

    commit('setRecipesTags', data);
  },
  async getRecipe({ commit }, { recipeId }) {
    const { data } = await this.$axios.$get(`/customer-api/v2/recipes/${recipeId}`);

    commit('setRecipe', data);
  },
  async getFarmers({ commit }) {
    const { data } = await this.$axios.$get('/customer-api/v2/pages/boeren');

    commit('setFarmers', data);
  },
  async getMainNavigation({ commit }) {
    const { data } = await this.$axios.$get('/customer-api/v2/navigation/main');

    commit('setMainNavigation', data);
  },
  async getProduct({ commit }, { productId, include = [] }) {
    const { data } = await this.$axios.$get(`/customer-api/v2/products/${productId}`, { params: { include } });

    commit('setProduct', data);
  },
  async getDeliveryData({ commit }, { zipcode }) {
    try {
      const deliveryData = await this.$axios.$get(`/customer-api/v2/customers/zipcode-on-route/${zipcode}`);

      if (deliveryData) {
        commit('setValidationErrors', {});
        commit('setDeliveryData', deliveryData.data);
      }
    } catch ({ response }) {
      commit('setDeliveryData', {});
      if (response.status === 422) {
        commit('setValidationErrors', { invalidZipcode: ['Geen geldige postcode'] });
      }
      if (response.status === 404) {
        commit('setValidationErrors', { zipcode: ['Wij leveren op dit moment niet op dit adres'] });
      }
    }
  },
  async addAddress({ commit }, payload) {
    try {
      const { data } = await this.$axios.$post('/customer-api/v2/addresses', payload);
      commit('setAddress', data);
      commit('setValidationErrors', {});
    } catch ({ response }) {
      commit('setValidationErrors', response.data.errors);
    }
  },
  async deleteAddress({ commit }, addressId) {
    try {
      await this.$axios.$delete(`/customer-api/v2/addresses/${addressId}`);
    } catch ({ response }) {
      commit('setValidationErrors', { message: [response.message] });
    }
  },
  async updateAddress({ commit }, payload) {
    try {
      await this.$axios.$patch(`/customer-api/v2/addresses/${payload.id}`, payload);

      commit('setValidationErrors', {});
    } catch (e) {
      if (e.response) {
        const { errors } = e.response.data;

        commit('setValidationErrors', errors);
      }
    }
  },
  async setDefaultAddress({ commit }, { type, addressId }) {
    try {
      await this.$axios.$patch(`/customer-api/v2/addresses/${addressId}/default`, { type });
    } catch ({ response }) {
      commit('setValidationErrors', { message: [response.message] });
    }
  },
  async attachPickupToCustomer({ commit }, { pickupId }) {
    try {
      await this.$axios.patch(`/customer-api/v2/addresses/${pickupId}/attach-to-customer`);
    } catch ({ response }) {
      commit('setValidationErrors', { message: [response.message] });
    }
  },
  async updatePickupAddress({ commit }, { oldPickupId, newPickupId, defaultShipping }) {
    try {
      await this.$axios.patch(`/customer-api/v2/addresses/${newPickupId}/attach-to-customer`);
      if (defaultShipping) {
        await this.$axios.$patch(`/customer-api/v2/addresses/${newPickupId}/default`, { type: 'shipping' });
      }
      await this.$axios.$delete(`/customer-api/v2/addresses/${oldPickupId}`);
    } catch ({ response }) {
      commit('setValidationErrors', { message: [response.message] });
    }
  },
  async getFullAddress({ commit }, { zipcode, housenumber }) {
    try {
      const data = await this.$axios.$get(`/customer-api/v2/addresses/enrich/${zipcode}/${housenumber}`);

      commit('setValidationErrors', {});
      commit('setFullAddress', data);
    } catch ({ response }) {
      commit('setFullAddress', {});
      commit('setValidationErrors', { street: response.data.message });
    }
  },
  async getPickupPoints({ commit }) {
    const { data } = await this.$axios.$get('/customer-api/v2/pickuppoints');

    commit('setPickupPoints', data);
  },
  async getOrders({ commit }, params) {
    const data = await this.$axios.$get(`/customer-api/v2/customers/${this.$auth.user.id}/orders`, { params });

    commit('setOrders', data);
  },
  async getOrder({ commit }, { orderId, includes = [] }) {
    const { data } = await this.$axios.$get(`/customer-api/v2/customers/${this.$auth.user.id}/orders/${orderId}`, { params: { include: includes.join(',') } });

    commit('setOrder', data);
  },
  async getProductLists({ commit }) {
    const { data } = await this.$axios.$get('/customer-api/v2/product-lists', { params: { include: 'items.product' } });

    commit('setProductLists', data);
  },
  async getProductList({ commit }, { id }) {
    const { data } = await this.$axios.$get(`/customer-api/v2/product-lists/${id}`, { params: { include: 'items.product' } });

    commit('setProductList', data);
  },
  async createProductList({ commit }, { name }) {
    try {
      const { data } = await this.$axios.$post('/customer-api/v2/product-lists', { name });

      commit('addProductList', data);
    } catch ({ response }) {
      commit('setValidationErrors', { message: [response.message] });
    }
  },
  async deleteProductList({ commit }, { listId }) {
    try {
      await this.$axios.$delete(`/customer-api/v2/product-lists/${listId}`);
    } catch ({ response }) {
      commit('setValidationErrors', { message: [response.message] });
    }
  },
  async createProductListItem({ commit }, payload) {
    try {
      const { data } = await this.$axios.$post(`/customer-api/v2/product-lists/${payload.product_list_id}/items`, payload);

      commit('addProductListItem', data);
    } catch ({ response }) {
      commit('setValidationErrors', { message: [response.message] });
    }
  },
  async deleteProductListItem({ commit }, { listId, itemId }) {
    try {
      await this.$axios.$delete(`/customer-api/v2/product-lists/${listId}/items/${itemId}`);

      commit('deleteProductlistItem', { itemId, listId });
    } catch ({ response }) {
      commit('setValidationErrors', { message: [response.message] });
    }
  },
  async createCustomer({ commit }, {
    email,
    password,
    firstName,
    lastName,
    zipcode,
    mobilePhone,
    mobilePhoneCountry,
    phone,
    phoneCountry,
    newsletter,
    metaData,
  }) {
    try {
      await this.$axios.post('/customer-api/v2/customers', {
        email,
        password,
        firstname: firstName,
        lastname: lastName,
        zipcode,
        mobile_phone: mobilePhone,
        mobile_phone_country: mobilePhoneCountry,
        phone,
        phone_country: phoneCountry,
        newsletter,
        meta_data: metaData,
      });
    } catch (e) {
      if (e.response) {
        const { errors } = e.response.data;

        commit('setValidationErrors', errors);
      }
    }
  },
  async resetPassword({ commit }, payload) {
    try {
      await this.$axios.post('/customer-api/v2/customers/reset', payload);
    } catch ({ response }) {
      commit('setValidationErrors', response.data.errors);
    }
  },
  async validatePasswordReset({ commit }, { email, token }) {
    try {
      await this.$axios.get(`/customer-api/v2/customers/check-reset-token/${email}/${token}`);
    } catch ({ response }) {
      if (response) {
        commit('setValidationErrors', { status: response.status, message: response.statusText });
      }
    }
  },
  async confirmPasswordChange({ commit }, payload) {
    try {
      await this.$axios.post('/customer-api/v2/customers/confirm', payload);
    } catch ({ response }) {
      commit('setValidationErrors', response.data.errors || { status: response.status, message: response.statusText });
    }
  },
  async getRecurringProducts({ commit }) {
    const { data } = await this.$axios.$get('/customer-api/v2/recurring-products');

    commit('setRecurringProducts', data);
  },
  async getUpcomingDeliveries({ commit }) {
    try {
      const { data } = await this.$axios.$get('/customer-api/v2/recurring-products/by-delivery?include=items');

      commit('setUpcomingDeliveries', data);
    } catch ({ response }) {
      commit('setValidationErrors', { status: response.status, message: response.data.message });
    }
  },
  async updateRecurringProduct({ commit }, payload) {
    try {
      const { data } = await this.$axios.$post('/customer-api/v2/recurring-products', payload);

      commit('setRecurringProducts', data);
    } catch ({ response }) {
      commit('setValidationErrors', response.data.errors);
    }
  },
  async deleteRecurringProduct(context, { productId }) {
    await this.$axios.$delete(`/customer-api/v2/recurring-products/${productId}`);
  },
  async deleteAllRecurringProducts({ commit }) {
    try {
      await this.$axios.$delete('/customer-api/v2/recurring-products');
    } catch ({ response }) {
      commit('setValidationErrors', { message: [response.data.message] });
    }
  },
  async updateCustomer({ commit }, payload) {
    try {
      await this.$axios.patch('/customer-api/v2/customers', payload);
    } catch (e) {
      if (e.response) {
        const { errors } = e.response.data;

        commit('setValidationErrors', errors);
      }
      console.error(e);
    }
  },
  async getNewsItems({ commit }) {
    const { data } = (await this.$axios.$get('/customer-api/v2/pages/nieuws'));

    commit('setNewsItems', data);
  },
  async getFavoritedRecipes({ commit }, payload) {
    const customerId = this.$auth.loggedIn ? this.$auth.user.id : null;
    const page = payload && payload.page ? { params: { page: payload.page } } : {};
    const data = await this.$axios.$get(`/customer-api/v2/customers/${customerId}/recipes`, { ...page });

    commit('setFavoritedRecipes', data);
  },
  async deleteFavoritedRecipe(context, { recipeId }) {
    await this.$axios.$delete(`/customer-api/v2/customers/${this.$auth.user.id}/recipes/${recipeId}`);
  },
  async addFavoritedRecipe(context, { recipeId }) {
    await this.$axios.$post(`/customer-api/v2/customers/${this.$auth.user.id}/recipes/${recipeId}`);
  },
  unsetValidationErrors({ commit }) {
    commit('setValidationErrors', {});
  },
  unsetValidationError({ commit }, payload) {
    commit('unsetValidationError', payload);
  },
  async getCrates({ commit }) {
    const data = await this.$axios.$get('/customer-api/v2/customers/crates');

    commit('setCrates', data);
  },
  async getHolidays({ commit }) {
    const { data } = await this.$axios.$get('/customer-api/v2/customers/holidays');

    commit('setHolidays', data);
  },
  async deleteHoliday({ commit }, { holidayId }) {
    try {
      const data = await this.$axios.$delete(`/customer-api/v2/customers/holidays/${holidayId}`);
      commit('setHolidays', data.data);
    } catch ({ response }) {
      commit('setValidationErrors', { message: [response.message] });
    }
  },
  async addHoliday({ commit }, payload) {
    try {
      const data = await this.$axios.$post('/customer-api/v2/customers/holidays', payload);

      commit('setHolidays', data.data);
    } catch ({ response }) {
      commit('setValidationErrors', response.data.errors);
    }
  },
  async deleteCartProduct({ commit }, { cartId, productId }) {
    try {
      const { data } = await this.$axios.$delete(`/customer-api/v2/carts/${cartId}/product/${productId}?include=discount_items,discount_type_totals`);

      this.$auth.$storage.setState('user', {
        ...this.$auth.$storage.state.user,
        cart: { data },
      });
    } catch ({ response }) {
      commit('setValidationErrors', { message: [response.message] });
    }
  },
  async addCartProducts({ commit }, products) {
    const { user } = this.$auth;
    const cartId = user && user.cart ? user.cart.data.id : null;

    try {
      await this.$axios.$post(`/customer-api/v2/carts/${cartId}/add-products`, { products });
    } catch ({ response }) {
      if (response) {
        commit('setValidationErrors', response.data.errors);
      }
    }
  },
  async updateCart({ commit }, payload) {
    try {
      await this.$axios.patch(`/customer-api/v2/carts/${payload.cartId}`, payload);
    } catch ({ response }) {
      commit('setValidationErrors', { message: [response.data.message] });
    }
  },
  async updateCartProduct({ commit }, payload) {
    const { user } = this.$auth;
    const cartId = user && user.cart ? { cart_id: user.cart.data.id } : {};

    try {
      const { data, meta } = await this.$axios.$post('/customer-api/v2/carts/update-product?include=discount_items,discount_type_totals', {
        ...payload,
        ...cartId,
      });

      this.$auth.$storage.setState('user', {
        ...this.$auth.$storage.state.user,
        cart: { data },
        flashMessages: meta.flash_messages,
      });
    } catch ({ response }) {
      if (response) {
        commit('setValidationErrors', response.data.errors || { message: [response.data.message] });
      }
    }
  },
  async deleteOrder({ commit }, { customerId, orderId }) {
    try {
      await this.$axios.delete(`/customer-api/v2/customers/${customerId}/orders/${orderId}`);

      delete this.$auth.$storage.state.user.current_order;

      this.$auth.$storage.setState('user', {
        ...this.$auth.$storage.state.user,
      });
    } catch ({ response }) {
      if (response) {
        commit('setValidationErrors', { message: [response.data.message] });
      }
    }
  },
  async deleteCartItems({ commit }, { cartId }) {
    try {
      await this.$axios.patch(`/customer-api/v2/carts/${cartId}/remove-items`);
    } catch (e) {
      commit('setValidationErrors', { message: ['Er ging iets mis, kon producten niet uit winkelwagen verwijderen'] });
    }
  },
  async addCoupon({ commit }, { cartId, couponCode }) {
    try {
      await this.$axios.post(`/customer-api/v2/carts/${cartId}/coupons`, {
        coupon_code: couponCode,
      });
    } catch ({ response }) {
      commit('setValidationErrors', response.data.errors || { invalid: [response.data.message] });
    }
  },
  async deleteCoupon({ commit }, { cartId, couponId }) {
    try {
      await this.$axios.delete(`/customer-api/v2/carts/${cartId}/coupons/${couponId}`);
    } catch ({ response }) {
      commit('setValidationErrors', { message: [response.data.message] });
    }
  },
  async checkoutCart({ commit }, { cartId }) {
    try {
      await this.$axios.post(`/customer-api/v2/carts/${cartId}/checkout`);
    } catch ({ response }) {
      commit('setValidationErrors', { message: [response.data.message] });
    }
  },
  async verifyByPayment({ commit }, { redirectPath }) {
    try {
      const { host, protocol } = window.location;
      const { data } = await this.$axios.$patch('/customer-api/v2/customers/verify-iban-by-payment', {
        redirect_url: `${protocol}//${host}${redirectPath}`,
      });

      commit('setVerifiedPaymentInfo', data);
    } catch ({ response }) {
      commit('setValidationErrors', { message: [response.data.message] });
    }
  },
  async subscribeNewsletter({ commit }, payload) {
    try {
      await this.$axios.$post('/customer-api/v2/subscriptions/newsletter', payload);
    } catch ({ response }) {
      commit('setValidationErrors', response.data.errors);
    }
  },
  async getPackageSlip({ commit }, { orderId }) {
    try {
      const data = await this.$axios.$get(`/customer-api/v2/customers/${this.$auth.user.id}/orders/${orderId}/package-slip`);

      commit('setPackageSlip', data);
    } catch ({ response }) {
      commit('setValidationErrors', response.data.errors);
    }
  },
  unsetVerifiedPaymentInfo({ commit }) {
    commit('verifiedPaymentInfo', {});
  },
  async getSupplierProducts({ commit }, { supplierId }) {
    try {
      const { data } = await this.$axios.$get(`/customer-api/v2/products/supplier/${supplierId}`);
      commit('setSupplierProducts', data);
    } catch ({ response }) {
      commit('setValidationErrors', response.data.errors);
    }
  },
};
