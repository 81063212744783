export default ({ store, route, redirect }) => {
  const { redirects } = store.getters;

  const url = route.path.split('?')[0].substring(1);
  let urlParams = null;
  if (route.path.includes('?')) {
    urlParams = `?${route.path.split('?')[1]}`;
  }

  const redirectTo = redirects.find((r) => r.path === url);

  if (redirectTo) {
    let newLocation;
    if (urlParams) {
      newLocation = redirectTo.redirect_path + urlParams;
    } else {
      newLocation = redirectTo.redirect_path;
    }

    redirect(`/${newLocation}`);
  }
};
