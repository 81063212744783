export const index = {};

export const authFetchMixin = {
  methods: {
    authFetch() {
      if (this.$auth.loggedIn) {
        this.$store.dispatch('api/getRecurringProducts');
        this.$store.dispatch('api/getFavoritedRecipes');
      }
    },
  },
};
