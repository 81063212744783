import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _68390832 = () => interopDefault(import('../src/pages/aanbiedingen/index.vue' /* webpackChunkName: "pages/aanbiedingen/index" */))
const _1c5a9c8d = () => interopDefault(import('../src/pages/aanmelden/index.vue' /* webpackChunkName: "pages/aanmelden/index" */))
const _f486cb1a = () => interopDefault(import('../src/pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _2b235073 = () => interopDefault(import('../src/pages/boeren/index.vue' /* webpackChunkName: "pages/boeren/index" */))
const _7afdafd2 = () => interopDefault(import('../src/pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _382bee59 = () => interopDefault(import('../src/pages/maintenance/index.vue' /* webpackChunkName: "pages/maintenance/index" */))
const _2eaa619e = () => interopDefault(import('../src/pages/producten/index.vue' /* webpackChunkName: "pages/producten/index" */))
const _3c568d92 = () => interopDefault(import('../src/pages/recepten/index.vue' /* webpackChunkName: "pages/recepten/index" */))
const _7ec28458 = () => interopDefault(import('../src/pages/registreren/index.vue' /* webpackChunkName: "pages/registreren/index" */))
const _38b55eee = () => interopDefault(import('../src/pages/verhalen/index.vue' /* webpackChunkName: "pages/verhalen/index" */))
const _5bae844b = () => interopDefault(import('../src/pages/winkelmandje/index.vue' /* webpackChunkName: "pages/winkelmandje/index" */))
const _2d550963 = () => interopDefault(import('../src/pages/aanmelden/wachtwoord-vergeten/index.vue' /* webpackChunkName: "pages/aanmelden/wachtwoord-vergeten/index" */))
const _fca55fe4 = () => interopDefault(import('../src/pages/account/adressen.vue' /* webpackChunkName: "pages/account/adressen" */))
const _55cfecca = () => interopDefault(import('../src/pages/account/bestelhistorie.vue' /* webpackChunkName: "pages/account/bestelhistorie" */))
const _35f65b3a = () => interopDefault(import('../src/pages/account/betaalgegevens.vue' /* webpackChunkName: "pages/account/betaalgegevens" */))
const _71428c9a = () => interopDefault(import('../src/pages/account/betaling-status.vue' /* webpackChunkName: "pages/account/betaling-status" */))
const _56c0523c = () => interopDefault(import('../src/pages/account/bewerken.vue' /* webpackChunkName: "pages/account/bewerken" */))
const _10db1db5 = () => interopDefault(import('../src/pages/account/favorieten/index.vue' /* webpackChunkName: "pages/account/favorieten/index" */))
const _22cf0a50 = () => interopDefault(import('../src/pages/account/mijn-bestellingen.vue' /* webpackChunkName: "pages/account/mijn-bestellingen" */))
const _16a5cef6 = () => interopDefault(import('../src/pages/account/mijn-kratten.vue' /* webpackChunkName: "pages/account/mijn-kratten" */))
const _465311f1 = () => interopDefault(import('../src/pages/account/recepten.vue' /* webpackChunkName: "pages/account/recepten" */))
const _d696d65e = () => interopDefault(import('../src/pages/account/vakanties.vue' /* webpackChunkName: "pages/account/vakanties" */))
const _053c7e05 = () => interopDefault(import('../src/pages/account/vaste-producten.vue' /* webpackChunkName: "pages/account/vaste-producten" */))
const _083f202a = () => interopDefault(import('../src/pages/account/voorkeuren.vue' /* webpackChunkName: "pages/account/voorkeuren" */))
const _45782838 = () => interopDefault(import('../src/pages/bestellen/betaling.vue' /* webpackChunkName: "pages/bestellen/betaling" */))
const _198a6b1a = () => interopDefault(import('../src/pages/bestellen/bezorging.vue' /* webpackChunkName: "pages/bestellen/bezorging" */))
const _6925d6fb = () => interopDefault(import('../src/pages/producten/zoekresultaten/index.vue' /* webpackChunkName: "pages/producten/zoekresultaten/index" */))
const _6ee1ed46 = () => interopDefault(import('../src/pages/account/favorieten/_id.vue' /* webpackChunkName: "pages/account/favorieten/_id" */))
const _658a0120 = () => interopDefault(import('../src/pages/producten/zoekresultaten/_query.vue' /* webpackChunkName: "pages/producten/zoekresultaten/_query" */))
const _76286f3d = () => interopDefault(import('../src/pages/aanmelden/wachtwoord-vergeten/_email/_token.vue' /* webpackChunkName: "pages/aanmelden/wachtwoord-vergeten/_email/_token" */))
const _46919062 = () => interopDefault(import('../src/pages/aanbiedingen/_aanbieding.vue' /* webpackChunkName: "pages/aanbiedingen/_aanbieding" */))
const _1d961de3 = () => interopDefault(import('../src/pages/boeren/_farmer.vue' /* webpackChunkName: "pages/boeren/_farmer" */))
const _129e5ad8 = () => interopDefault(import('../src/pages/producten/_product.vue' /* webpackChunkName: "pages/producten/_product" */))
const _c895b026 = () => interopDefault(import('../src/pages/recepten/_recipe.vue' /* webpackChunkName: "pages/recepten/_recipe" */))
const _6cc9548e = () => interopDefault(import('../src/pages/registreren/_zipcode.vue' /* webpackChunkName: "pages/registreren/_zipcode" */))
const _7450b94f = () => interopDefault(import('../src/pages/verhalen/_story.vue' /* webpackChunkName: "pages/verhalen/_story" */))
const _215bfad5 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1020f1ca = () => interopDefault(import('../src/pages/_content/index.vue' /* webpackChunkName: "pages/_content/index" */))
const _43b3ead7 = () => interopDefault(import('../src/pages/_content/_.vue' /* webpackChunkName: "pages/_content/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aanbiedingen",
    component: _68390832,
    name: "aanbiedingen"
  }, {
    path: "/aanmelden",
    component: _1c5a9c8d,
    name: "aanmelden"
  }, {
    path: "/account",
    component: _f486cb1a,
    name: "account"
  }, {
    path: "/boeren",
    component: _2b235073,
    name: "boeren"
  }, {
    path: "/logout",
    component: _7afdafd2,
    name: "logout"
  }, {
    path: "/maintenance",
    component: _382bee59,
    name: "maintenance"
  }, {
    path: "/producten",
    component: _2eaa619e,
    name: "producten"
  }, {
    path: "/recepten",
    component: _3c568d92,
    name: "recepten"
  }, {
    path: "/registreren",
    component: _7ec28458,
    name: "registreren"
  }, {
    path: "/verhalen",
    component: _38b55eee,
    name: "verhalen"
  }, {
    path: "/winkelmandje",
    component: _5bae844b,
    name: "winkelmandje"
  }, {
    path: "/aanmelden/wachtwoord-vergeten",
    component: _2d550963,
    name: "aanmelden-wachtwoord-vergeten"
  }, {
    path: "/account/adressen",
    component: _fca55fe4,
    name: "account-adressen"
  }, {
    path: "/account/bestelhistorie",
    component: _55cfecca,
    name: "account-bestelhistorie"
  }, {
    path: "/account/betaalgegevens",
    component: _35f65b3a,
    name: "account-betaalgegevens"
  }, {
    path: "/account/betaling-status",
    component: _71428c9a,
    name: "account-betaling-status"
  }, {
    path: "/account/bewerken",
    component: _56c0523c,
    name: "account-bewerken"
  }, {
    path: "/account/favorieten",
    component: _10db1db5,
    name: "account-favorieten"
  }, {
    path: "/account/mijn-bestellingen",
    component: _22cf0a50,
    name: "account-mijn-bestellingen"
  }, {
    path: "/account/mijn-kratten",
    component: _16a5cef6,
    name: "account-mijn-kratten"
  }, {
    path: "/account/recepten",
    component: _465311f1,
    name: "account-recepten"
  }, {
    path: "/account/vakanties",
    component: _d696d65e,
    name: "account-vakanties"
  }, {
    path: "/account/vaste-producten",
    component: _053c7e05,
    name: "account-vaste-producten"
  }, {
    path: "/account/voorkeuren",
    component: _083f202a,
    name: "account-voorkeuren"
  }, {
    path: "/bestellen/betaling",
    component: _45782838,
    name: "bestellen-betaling"
  }, {
    path: "/bestellen/bezorging",
    component: _198a6b1a,
    name: "bestellen-bezorging"
  }, {
    path: "/producten/zoekresultaten",
    component: _6925d6fb,
    name: "producten-zoekresultaten"
  }, {
    path: "/account/favorieten/:id",
    component: _6ee1ed46,
    name: "account-favorieten-id"
  }, {
    path: "/producten/zoekresultaten/:query",
    component: _658a0120,
    name: "producten-zoekresultaten-query"
  }, {
    path: "/aanmelden/wachtwoord-vergeten/:email?/:token",
    component: _76286f3d,
    name: "aanmelden-wachtwoord-vergeten-email-token"
  }, {
    path: "/aanbiedingen/:aanbieding",
    component: _46919062,
    name: "aanbiedingen-aanbieding"
  }, {
    path: "/boeren/:farmer",
    component: _1d961de3,
    name: "boeren-farmer"
  }, {
    path: "/producten/:product",
    component: _129e5ad8,
    name: "producten-product"
  }, {
    path: "/recepten/:recipe",
    component: _c895b026,
    name: "recepten-recipe"
  }, {
    path: "/registreren/:zipcode",
    component: _6cc9548e,
    name: "registreren-zipcode"
  }, {
    path: "/verhalen/:story",
    component: _7450b94f,
    name: "verhalen-story"
  }, {
    path: "/",
    component: _215bfad5,
    name: "index"
  }, {
    path: "/:content",
    component: _1020f1ca,
    name: "content"
  }, {
    path: "/:content/*",
    component: _43b3ead7,
    name: "content-all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
