export const index = {};

export const breakpointMixin = {
  computed: {
    isMobile() {
      return this.$mq === 'xs' || this.$mq === 'sm' || this.$mq === 'md';
    },
    isDesktop() {
      return this.$mq === 'lg' || this.$mq === 'xl';
    },
  },
};
