export default (context, inject) => {
  const isEmbedRegex = /^.*((youtube.com\/|youtu.be\/))embed/;
  const idRegex = /^.*((youtube.com\/|youtu.be\/)|v\/|u\/\w\/|watch\?v=|&v=)([^#&?]*).*/;

  inject('videoLink', (url) => {
    if (url.match(isEmbedRegex)) {
      return url;
    }

    const match = url.match(idRegex);

    const videoId = (match && match[3].length === 11)
      ? match[3]
      : null;

    if (videoId) {
      return `https://www.youtube.com/embed/${videoId}`;
    }

    return url;
  });
};
