import { createHmac } from 'crypto';
import axios from 'axios';

export const state = () => ({
  intercomUserHash: null,
  redirects: [],
  maintenance: {},
  shopCategoryGroups: [],
  shopDiscountLinks: [],
});

export const getters = {
  intercomUserHash: (state) => state.intercomUserHash,
  redirects: (state) => state.redirects,
  maintenance: (state) => state.maintenance,
  shopCategoryGroups: (state) => state.shopCategoryGroups,
  shopDiscountLinks: (state) => state.shopDiscountLinks,
};

export const mutations = {
  setIntercomUserHash(state, payload) {
    state.intercomUserHash = payload;
  },
  setRedirects(state, payload) {
    state.redirects = payload;
  },
  setMaintenance(state, payload) {
    state.maintenance = payload;
  },
  setShopCategoryGroups(state, payload) {
    state.shopCategoryGroups = payload;
  },
  setShopDiscountLinks(state, payload) {
    state.shopDiscountLinks = payload;
  },
};

export const actions = {
  createIntercomUserHash({ commit }, payload) {
    if (payload && process.env.intercomHashSecret) {
      const hmac = createHmac('sha256', process.env.intercomHashSecret);
      hmac.update(payload);

      commit('setIntercomUserHash', hmac.digest('hex'));
    }
  },
  async createRedirects({ commit }) {
    if (process.env.redirectJson) {
      try {
        const { data } = await axios.get(process.env.redirectJson);
        commit('setRedirects', data);
      } catch (error) {
        console.error(error);
      }
    }
  },
  async getSettings({ commit }) {
    if (process.env.settingsJson) {
      try {
        const { data } = await axios.get(process.env.settingsJson);
        commit('setMaintenance', data.maintenance);
        commit('setShopCategoryGroups', data.shop_category_groups || []);
        commit('setShopDiscountLinks', Object.entries(data)
          .filter(([key]) => key.startsWith('shop_discount_link'))
          .map(([, value]) => value),
        );
      } catch (error) {
        console.error(error);
      }
    }
  },
  async nuxtServerInit({ state: { auth }, dispatch }, { req }) {
    if (auth.loggedIn && req.originalUrl !== '/') {
      await dispatch('createIntercomUserHash', auth.user.id.toString());
    }

    await dispatch('getSettings');
    await dispatch('createRedirects');
    await dispatch('api/getMainNavigation');
  },
};
