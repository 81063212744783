export const state = () => ({
  searchValue: '',
  notification: {
    message: '',
    timeout: 4000,
    active: false,
    type: 'success',
  },
  registeredClickEvent: {
    pageX: null,
    pageY: null,
    options: null,
  },
  allergenTypes: [
    {
      id: 'bdih',
      srcSets: [
        { src: require('~/assets/icons/bdih.png?webp'), type: 'image/webp' },
        { src: require('~/assets/icons/bdih.png'), type: 'image/png' },
      ],
      fallback: require('~/assets/icons/bdih.png'),
    },
    {
      id: 'biologisch',
      srcSets: [
        { src: require('~/assets/icons/biologisch.png?webp'), type: 'image/webp' },
        { src: require('~/assets/icons/biologisch.png'), type: 'image/png' },
      ],
      fallback: require('~/assets/icons/biologisch.png'),
    },
    {
      id: 'ecocert',
      srcSets: [
        { src: require('~/assets/icons/ecocert.png?webp'), type: 'image/webp' },
        { src: require('~/assets/icons/ecocert.png'), type: 'image/png' },
      ],
      fallback: require('~/assets/icons/ecocert.png'),
    },
    {
      id: 'glutenvrij',
      srcSets: [
        { src: require('~/assets/icons/glutenvrij.png?webp'), type: 'image/webp' },
        { src: require('~/assets/icons/glutenvrij.png'), type: 'image/png' },
      ],
      fallback: require('~/assets/icons/glutenvrij.png'),
    },
    {
      id: 'natureorg',
      srcSets: [
        { src: require('~/assets/icons/natureorg.png?webp'), type: 'image/webp' },
        { src: require('~/assets/icons/natureorg.png'), type: 'image/png' },
      ],
      fallback: require('~/assets/icons/natureorg.png'),
    },
    {
      id: 'suikervrij',
      srcSets: [
        { src: require('~/assets/icons/suikervrij.png?webp'), type: 'image/webp' },
        { src: require('~/assets/icons/suikervrij.png'), type: 'image/png' },
      ],
      fallback: require('~/assets/icons/suikervrij.png'),
    },
    {
      id: 'veganistisch',
      srcSets: [
        { src: require('~/assets/icons/veganistisch.png?webp'), type: 'image/webp' },
        { src: require('~/assets/icons/veganistisch.png'), type: 'image/png' },
      ],
      fallback: require('~/assets/icons/veganistisch.png'),
    },
    {
      id: 'vegetarisch',
      srcSets: [
        { src: require('~/assets/icons/vegetarisch.png?webp'), type: 'image/webp' },
        { src: require('~/assets/icons/vegetarisch.png'), type: 'image/png' },
      ],
      fallback: require('~/assets/icons/vegetarisch.png'),
    },
  ],
  footerLogos: [
    {
      srcSets: [
        { src: require('~/assets/images/footer-logos/demeter.png?webp'), type: 'image/webp' },
        { src: require('~/assets/images/footer-logos/demeter.png'), type: 'image/png' },
      ],
      fallback: require('~/assets/images/footer-logos/demeter.png'),
      maxWidth: 120,
    },
    {
      srcSets: [
        { src: require('~/assets/images/footer-logos/eko.png?webp'), type: 'image/webp' },
        { src: require('~/assets/images/footer-logos/eko.png'), type: 'image/png' },
      ],
      fallback: require('~/assets/images/footer-logos/eko.png'),
      maxWidth: 120,
    },
    {
      srcSets: [
        { src: require('~/assets/images/footer-logos/kiyoh-logo.png?webp'), type: 'image/webp' },
        { src: require('~/assets/images/footer-logos/kiyoh-logo.png'), type: 'image/png' },
      ],
      fallback: require('~/assets/images/footer-logos/kiyoh-logo.png'),
    },
    {
      srcSets: [
        { src: require('~/assets/images/footer-logos/webshop-keurmerk-logo.png?webp'), type: 'image/webp' },
        { src: require('~/assets/images/footer-logos/webshop-keurmerk-logo.png'), type: 'image/png' },
      ],
      fallback: require('~/assets/images/footer-logos/webshop-keurmerk-logo.png'),
    },
  ],
  cartPopup: {
    open: false,
    hide: false,
  },
  categoryPopup: false,
  registrationPopup: {
    open: false,
    product: null,
    amount: null,
    refresh: false,
  },
  iosPwaInstallPopup: {
    open: false,
    dismissed: false,
  },
  redirectLink: '/',
});

export const getters = {
  notification: (state) => state.notification,
  allergenTypes: (state) => state.allergenTypes,
  footerLogos: (state) => state.footerLogos,
  cartPopup: (state) => state.cartPopup,
  categoryPopup: (state) => state.categoryPopup,
  registrationPopup: (state) => state.registrationPopup,
  registeredClickEvent: (state) => state.registeredClickEvent,
  redirectLink: (state) => state.redirectLink,
  searchValue: (state) => state.searchValue,
  iosPwaInstallPopup: (state) => state.iosPwaInstallPopup,
};

export const mutations = {
  setNotification(state, payload) {
    state.notification = payload;
  },
  setCartPopup(state, payload) {
    state.cartPopup = payload;
  },
  toggleCategoryPopup(state, payload) {
    state.categoryPopup = payload;
  },
  toggleRegistrationPopup(state, payload) {
    state.registrationPopup = payload;
  },
  setRegisteredClickEvent(state, payload) {
    state.registeredClickEvent = payload;
  },
  setRedirectLink(state, payload) {
    state.redirectLink = payload;
  },
  setSearchValue(state, payload) {
    state.searchValue = payload;
  },
  setIosPwaInstallPopup(state, payload) {
    state.iosPwaInstallPopup = payload;
    localStorage.setItem('iosPwaInstallPopupDismissed', String(payload.dismissed));
  },
};

export const actions = {
  toggleNotification({ commit }, payload) {
    commit('setNotification', payload);
  },
  toggleCartPopup({ commit, state }, payload) {
    commit('setCartPopup', { ...state.cartPopup, open: payload });
  },
  async updateCartPopup({ commit, state }) {
    const { cart } = this.$auth.user;

    if (cart) {
      const items = cart.data.items.data.map((item) => ({ ...item, on_order: Number(item.on_order) }));
      const unconfirmedItems = (items.filter((item) => item.amount !== item.on_order));
      const unconfirmed = unconfirmedItems.length === 1 && unconfirmedItems[0].amount === 1;
      const confirmed = items.filter((item) => item.on_order).length;
      const hide = Boolean(localStorage.getItem('cartPopup') || false);

      if (unconfirmed && confirmed && !hide) {
        commit('setCartPopup', { open: true, hide: false });
      }
    }
  },
  toggleCategoryPopup({ commit }, payload) {
    commit('toggleCategoryPopup', payload);
  },
  toggleRegistrationPopup({ commit }, payload) {
    commit('toggleRegistrationPopup', payload);
  },
  setRegisteredClickEvent({ commit }, payload) {
    commit('setRegisteredClickEvent', payload);
  },
  setRedirectLink({ commit }, payload) {
    commit('setRedirectLink', payload);
  },
  setSearchValue({ commit }, payload) {
    commit('setSearchValue', payload);
  },
};
