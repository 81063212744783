export default async ({
  $moment, route, redirect, store,
}) => {
  const { maintenance } = store.getters;

  if (maintenance && maintenance.active_until) {
    const maintenanceUntil = $moment.utc(maintenance.active_until).local();
    const today = $moment.parseZone(new Date());
    const isMaintenance = today.isBefore(maintenanceUntil);

    if (isMaintenance) {
      return redirect('/maintenance');
    }

    if (!isMaintenance && route.path === '/maintenance') {
      return redirect('/');
    }
  }

  if (route.path === '/maintenance') {
    return redirect('/');
  }
};
