<template lang="pug">
.error-layout
  breadcrumb-bar
    breadcrumb-button(
      slot="left"
      :link="previousRoute || '/'"
      icon-left="fa-angle-left"
      :use-maxwidth="false"
    )
      | {{ previousRoute ? 'Ga terug' : 'Homepagina' }}
  .container
    .content.page-content(v-if="error.statusCode === 500")
      h3.title Er ging iets mis
      p.message De pagina kon niet worden geladen door een fout
      a.button.secondary.stretch(href="/")
        i.far.fa-arrow-left.icon-left
        span Ga naar de homepagina
    .content.page-content(v-else)
      h3.title {{ error.title }}
      p.message {{ error.message }}
      nuxt-link.button.secondary(:to="previousRoute || '/'")
        i.far.fa-arrow-left.icon-left
        span {{ previousRoute ? 'Terug naar waar je gebleven was' : 'Ga naar de homepagina' }}
</template>

<script>
export default {
  components: {
    'breadcrumb-bar': () => import('@/components/breadcrumbs/breadcrumbBar'),
    'breadcrumb-button': () => import('@/components/breadcrumbs/breadcrumbButton'),
  },
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  head() {
    return {
      title: '404 - Pagina niet gevonden',
    };
  },
  data() {
    return {
      previousRoute: null,
    };
  },
  watch: {
    $route: {
      handler(newValue, oldValue) {
        this.previousRoute = oldValue.fullPath;
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
@import '~/assets/css/main.scss';

.error-layout{
  min-height: 800px;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 70px auto 30px auto;
    padding: 0 20px;
    box-sizing: border-box;

    .title {
      margin: 0;
    }

    .message {
      margin: 10px 0 30px 0;
    }
  }
  @media screen and (max-width: $breakpoint-md) {

    .container {
      margin: 30px auto;
    }
  }
}
</style>
