import { breakpointMixin } from '@/mixins/breakpointMixin';

export const index = {};

export const intercomMixin = {
  mixins: [breakpointMixin],
  computed: {
    intercomUserHash() {
      return this.$store.getters.intercomUserHash;
    },
    intercomCredentials() {
      if (this.$auth.loggedIn && this.intercomUserHash) {
        return {
          email: this.$auth.user.email,
          user_id: this.$auth.user.id,
          user_hash: this.intercomUserHash,
        };
      }

      return {};
    },
  },
  methods: {
    bootIntercom() {
      if (process.env.intercomAppId && process.env.intercomHashSecret) {
        window.Intercom('boot', {
          app_id: process.env.intercomAppId,
          hide_default_launcher: false, // TODO: add this.isMobile later
          ...this.intercomCredentials,
        });
      }
    },
    shutdownIntercom() {
      window.Intercom('shutdown');
    },
    showIntercom() {
      window.Intercom('show');
    },
    hideIntercom() {
      window.Intercom('hide');
    },
    async createIntercomUserHash() {
      if (this.$auth.loggedIn && process.env.intercomAppId) {
        await this.$store.dispatch('createIntercomUserHash', this.$auth.user.id.toString());
      }
    },
  },
};
